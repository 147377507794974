import { Link } from 'gatsby-plugin-locale';
import React, { FC } from 'react';
import logo from '../assets/images/intensio_logo.svg';
import rings from '../assets/images/intensio_rings.svg';
import { useMedia } from '../hooks/useMedia';
import { isBrowser } from '../utils';

interface Props {}

export const Header: FC<Props> = () => {
	const breakpoint = useMedia({ xs: 0, md: 768, lg: 1024 }, 'xs');

	return (
		<>
			{isBrowser && window.__PRISMIC_PREVIEW_DATA__ && (
				<div className="text-center p-4 bg-blue-300 text-blue-800 relative z-30 text-sm">
					<span className="mr-2 font-medium font-display">Let op!</span>
					Je kijkt nu naar een preview van de data op Primic. Wanneer dit in orde is, kan
					je publishen en duurt het even voor het ge-update is op de website.
				</div>
			)}
			<div className="container mx-auto z-20 relative  px-10 md:px-0">
				<div className="absolute right-0 top-0 opacity-50 -mt-16 hidden md:block md:-mr-40 lg:-mr-12">
					<img src={rings} alt="" width={breakpoint === 'lg' ? 350 : 290} />
				</div>
				<div className="flex py-6 justify-between align-middle">
					<Link to="/">
						<img
							src={logo}
							alt="intensio logo"
							width={breakpoint === 'lg' ? 200 : 150}
						/>
					</Link>
				</div>
			</div>
		</>
	);
};
