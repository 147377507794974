import { useEffect, useState, useMemo } from 'react';

type Config = {
	readonly [key: string]: number;
};

type MediaQuery = {
	breakpoint: string;
	maxWidth?: number;
	minWidth: number;
	query: string;
};

const createMediaQueries = (breakpoints: Config) => {
	const sortedBreakpoints = Object.keys(breakpoints).sort(
		(a, b) => breakpoints[b] - breakpoints[a]
	);

	return sortedBreakpoints.map((breakpoint, index) => {
		let query = '';
		const minWidth = breakpoints[breakpoint];
		const nextBreakpoint = sortedBreakpoints[index - 1] as string | undefined;
		const maxWidth = nextBreakpoint ? breakpoints[nextBreakpoint] : undefined;

		if (minWidth >= 0) {
			query = `(min-width: ${minWidth}px)`;
		}

		if (typeof maxWidth !== 'undefined') {
			if (query) {
				query += ' and ';
			}
			query += `(max-width: ${maxWidth - 1}px)`;
		}

		const mediaQuery: MediaQuery = {
			breakpoint,
			maxWidth,
			minWidth,
			query,
		};

		return mediaQuery;
	});
};

export function useMedia(breakpoints: Config, defaultValue: string) {
	const mediaQueries = useMemo(() => createMediaQueries(breakpoints), [breakpoints]);

	// Array containing a media query list for each query
	const mediaQueryLists = mediaQueries.map(({ breakpoint, query }) => ({
		query: typeof window !== 'undefined' && window.matchMedia(query),
		breakpoint,
	}));

	// Function that gets value based on matching media query
	const getValue = () => {
		const matches = mediaQueryLists.find(({ query }) => query && query.matches);
		// Return related value or defaultValue if none
		return matches ? matches.breakpoint : defaultValue;
	};

	// State and setter for matched value
	const [value, setValue] = useState(getValue);

	useEffect(
		() => {
			// Event listener callback
			// Note: By defining getValue outside of useEffect we ensure that it has ...
			// ... current values of hook args (as this hook callback is created once on mount).
			const handler = () => setValue(getValue);
			// Set a listener for each media query with above handler as callback.
			mediaQueryLists.forEach(({ query }) => query && query.addListener(handler));
			// Remove listeners on cleanup
			return () => mediaQueryLists.forEach(({ query }) => query && query.removeListener(handler));
		},
		[] // Empty array ensures effect is only run on mount and unmount
	);

	return value;
}
