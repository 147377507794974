import { GatsbyLinkProps } from 'gatsby';
import { Link as GatsbyLink } from 'gatsby-plugin-locale';
import React, { SFC } from 'react';

interface Props {
	color: 'primary' | 'secondary' | 'white';
	to: string;
}

type LinkProps = GatsbyLinkProps<object>;

export const Link: SFC<Props & LinkProps> = ({ color, children, to, className, ...rest }) => {
	let colors = '';

	switch (color) {
		case 'primary':
			colors = 'bg-primary text-white';
			break;
		case 'secondary':
			colors = 'bg-secondary text-white';
			break;
		case 'white':
			colors = 'bg-white text-primary';
			break;
	}

	return (
		<GatsbyLink
			to={to}
			className={`${colors} inline-block font-medium py-2 px-4 rounded text-sm shadow-md md:text-sm cursor-pointer font-display ${className ||
				''}`}
			{...rest}>
			{children}
		</GatsbyLink>
	);
};
