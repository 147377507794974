import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-locale';
import React, { SFC } from 'react';
import { Helmet } from 'react-helmet';
import { BreadcrumbList, WebPage, WithContext, WebSite, ListItem } from 'schema-dts';
import i18n from '../../../config/i18n';
import { get } from '../../utils';
import { SEOQuery } from './__generated__/SEOQuery';

interface ToolInfo {
	name: string;
}

export interface SEOProps {
	title?: string;
	description?: string;
	img?: string;
	pathname?: string;
	tool?: ToolInfo;
}

export const SEO: SFC<SEOProps> = ({ title, description, img, pathname = '/', tool }) => {
	const { locale } = useIntl();
	const { site } = useStaticQuery<SEOQuery>(query);
	const { defaultTitle, defaultDescription, siteLanguage, defaultTitleAlt } = i18n[
		locale as keyof typeof i18n
	];

	const siteMetadata = get(site, 'siteMetadata');
	const buildTime = get(site, 'buildTime');

	const seo: { title: string; description: string; image: string | null; url: string } = {
		title: `${title || defaultTitle} | ${defaultTitleAlt}`,
		description: description || defaultDescription,
		image: null, //`${siteMetadata.siteUrl}${img || siteMetadata.defaultBanner}`,
		url: `${siteMetadata.siteUrl}${pathname}`,
	};

	// schema.org in JSONLD format
	// https://developers.google.com/search/docs/guides/intro-structured-data

	const schemaOrgWebSite: WithContext<WebSite> = {
		'@context': 'https://schema.org',
		'@type': 'WebSite',
		url: siteMetadata.siteUrl,
		description: seo.description,
		name: defaultTitleAlt,
		publisher: {
			'@type': siteMetadata.author._type,
			...siteMetadata.author,
		} as any,
		copyrightHolder: {
			'@type': siteMetadata.author._type,
			...siteMetadata.author,
		} as any,
		copyrightYear: new Date().getFullYear(),
	};

	const schemaOrgWebPage: WithContext<WebPage> = {
		'@context': 'https://schema.org',
		'@type': 'WebPage',
		url: siteMetadata.siteUrl,
		inLanguage: siteLanguage,
		description: seo.description,
		name: seo.title,
		publisher: {
			'@type': siteMetadata.author._type,
			...siteMetadata.author,
		} as any,
		dateModified: buildTime,
	};

	// Initial breadcrumb list
	const breadcrumbs: WithContext<BreadcrumbList> | null = tool
		? {
				'@context': 'https://schema.org',
				'@type': 'BreadcrumbList',
				description: 'Breadcrumbs list',
				name: 'Breadcrumbs',
				itemListElement: [
					{
						'@type': 'ListItem',
						item: {
							'@id': siteMetadata.siteUrl,
							name: 'Tools',
						},
						position: 1,
					} as ListItem,
					{
						'@type': 'ListItem',
						item: {
							'@id': seo.url,
							name: tool.name,
						},
						position: 2,
					} as ListItem,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				],
		  }
		: null;

	return (
		<Helmet defer={false}>
			<html lang={siteLanguage} />
			<title>{seo.title}</title>
			<meta name="description" content={seo.description} />
			<meta name="keywords" content={siteMetadata.keywords} />
			{/* Insert schema.org data (webpage) + everytime (breadcrumbs) */}
			<script type="application/ld+json">{JSON.stringify(schemaOrgWebSite)}</script>
			<script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
			{breadcrumbs && (
				<script type="application/ld+json">{JSON.stringify(breadcrumbs)}</script>
			)}
			<link rel="preconnect" href="https://fonts.googleapis.com"></link>
			<link rel="preconnect" href="https://fonts.gstatic.com"></link>

			{/* Facebook */}
			<meta property="og:locale" content={siteMetadata.ogLanguage} />
			<meta property="og:url" content={seo.url} />
			<meta property="og:type" content="website" />
			<meta property="og:title" content={seo.title} />
			<meta property="og:description" content={seo.description} />
			{seo.image && (
				<>
					<meta property="og:image" content={seo.image} />
					<meta property="og:image:alt" content={description} />
				</>
			)}

			{/* Twitter */}
			{siteMetadata.twitter && <meta name="twitter:creator" content={siteMetadata.twitter} />}
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:title" content={seo.title} />
			<meta name="twitter:description" content={seo.description} />
			{seo.image && (
				<>
					<meta name="twitter:image" content={seo.image} />
					<meta name="twitter:image:alt" content={seo.description} />
				</>
			)}
		</Helmet>
	);
};

const query = graphql`
	query SEOQuery {
		site {
			buildTime(formatString: "DD-MM-YYYY")
			siteMetadata {
				keywords
				siteUrl
				defaultBanner: banner
				ogLanguage
				author {
					_type
					address {
						_type
						addressCountry
						addressLocality
						addressRegion
						postalCode
						streetAddress
					}
					contactPoint {
						_type
						contactType
						email
					}
					founder
					legalName
					logo
					name
					sameAs
					url
				}
				twitter
			}
		}
	}
`;
