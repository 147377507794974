module.exports = {
	title: 'Toolkit voor de (online) facilitator', // Navigation and Site Title
	titleAlt: 'Facilitatietools door Intensio', // Title for JSONLD
	description:
		'In deze toolkit vind je een overzicht van een 50-tal handige facilitatie tools om de procesbegeleider of facilitator te ondersteunen om (groeps)proces te faciliteren.',
	url: 'https://facilitatietools.be', // Domain of your site. No trailing slash!
	logo: '/static/logo.png', // Used for SEO
	ogLanguage: 'nl_BE', // Facebook Language
	keywords:
		'faciliteren, procesbegeleiding, workshop, vergaderen, methodieken, online faciliteren, facilitatie',

	// JSONLD / Manifest
	favicon: 'static/favicon.svg', // Used for manifest favicon generation
	shortName: 'Facilitatie tools', // shortname for manifest. MUST be shorter than 12 characters

	// https://schema.org/Organization
	author: {
		'@type': 'Organization',
		name: 'Intensio',
		legalName: 'Intensio BV',
		url: 'http://www.intensio.be',
		logo: 'https://www.intensio.be/intensio/intensio_logo.png',
		founder: 'Johan Snellinckx',
		address: {
			'@type': 'PostalAddress',
			streetAddress: 'Toekomststraat 23',
			addressLocality: 'Sint-Amandsberg',
			addressRegion: 'Oost-Vlaanderen',
			postalCode: '9040',
			addressCountry: 'Belgium',
		},
		contactPoint: {
			'@type': 'ContactPoint',
			contactType: 'info',
			email: 'info@intensio.be',
		},
		sameAs: [
			'https://www.linkedin.com/company/intensio',
			'https://www.facebook.com/intensioBE/',
		],
	}, // Author for schemaORGJSONLD
	themeColor: '#00597C',
	backgroundColor: '#ffffff',
	twitter: '@intensioBE',
	support: 'support@intensio.be',
};
