import React, { SFC } from 'react';
import { Header } from './Header';

interface Props {}

export const Layout: SFC<Props> = ({ children }) => {
	return (
		<div className="overflow-hidden">
			<Header />
			<main className="relative z-30">{children}</main>
			<footer className="py-4 text-center">
				<div className="container mx-auto text-white">
					<span className="text-secondary-600 text-sm px-4">
						Copyright Intensio {new Date().getFullYear()}
						{' - '}All rights reserved{' - '}
						<a
							href="https://www.intensio.be"
							className="text-primary underline"
							rel="noopener noreferrer"
							target="_blank">
							www.intensio.be
						</a>
					</span>
				</div>
			</footer>
		</div>
	);
};
