import { withPrefix } from 'gatsby';
import localeConfig from '../../config/i18n';
import { ShouldHaveProps } from '../declarations';
const defaultLocale = Object.values(localeConfig).find(l => l.default);

export function get<T, P1 extends keyof NonNullable<T>>(
	obj: T,
	prop1: P1
): ShouldHaveProps<NonNullable<T>[P1]>;

export function get<
	T,
	P1 extends keyof NonNullable<T>,
	P2 extends keyof NonNullable<NonNullable<T>[P1]>
>(obj: T, prop1: P1, prop2: P2): ShouldHaveProps<NonNullable<NonNullable<T>[P1]>[P2]>;

export function get<
	T,
	P1 extends keyof NonNullable<T>,
	P2 extends keyof NonNullable<NonNullable<T>[P1]>,
	P3 extends keyof NonNullable<NonNullable<NonNullable<T>[P1]>[P2]>
>(
	obj: T,
	prop1: P1,
	prop2: P2,
	prop3: P3
): ShouldHaveProps<NonNullable<NonNullable<NonNullable<T>[P1]>[P2]>[P3]>;

export function get<
	T,
	P1 extends keyof NonNullable<T>,
	P2 extends keyof NonNullable<NonNullable<T>[P1]>,
	P3 extends keyof NonNullable<NonNullable<NonNullable<T>[P1]>[P2]>,
	P4 extends keyof NonNullable<NonNullable<NonNullable<NonNullable<T>[P1]>[P2]>[P3]>
>(
	obj: T,
	prop1: P1,
	prop2: P2,
	prop3: P3,
	prop4: P4
): ShouldHaveProps<NonNullable<NonNullable<NonNullable<NonNullable<T>[P1]>[P2]>[P3]>[P4]>;

export function get(obj: any, ...props: string[]): any {
	return obj && props.reduce((result, prop) => (result == null ? undefined : result[prop]), obj);
}

export const sortByName = (a: { name: string }, b: { name: string }) => {
	if (a.name < b.name) {
		return -1;
	}
	if (a.name > b.name) {
		return 1;
	}
	return 0;
};

export const isBrowser = typeof window !== 'undefined';