const website = require('./website');

module.exports = {
	'nl': {
		default: true,
		path: 'nl',
		locale: 'nl-be',
		siteLanguage: 'nl',
		ogLang: 'nl_BE',
		defaultTitle: website.title,
		defaultTitleAlt: website.titleAlt,
		defaultDescription: website.description,
	},
	// 'en': {
	// 	path: 'en',
	// 	locale: 'en-gb',
	// 	siteLanguage: 'en',
	// 	ogLang: 'en_US',
	// 	defaultTitle: website.title,
	// 	defaultTitleAlt: website.titleAlt,
	// 	defaultDescription:
	// 		"Supporting the body's self-healing ability through shiatsu, breathing and dietary advice.",
	// 	headline: "'Heel' stands for 'not broken'",
	// },
	// 'fr': {
	// 	path: 'fr',
	// 	locale: 'fr-fr',
	// 	siteLanguage: 'fr',
	// 	ogLang: 'fr_FR',
	// 	defaultTitle: website.title,
	// 	defaultTitleAlt: website.titleAlt,
	// 	defaultDescription: 'TODO', // TODO localize
	// 	headline: 'TODO', // TODO lang
	// },
};
